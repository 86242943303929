<template>
    <v-app>
        <v-row>
            <v-col cols="12">
                <v-card flat :dark="dark" @mouseleave="suspicious(this)">

                    <v-card-title class="d-flex justify-content-between bg-primary text-white">

                        <div class="flex-grow-1">
                            Quiz topic
                        </div>
                        <div class="flex-grow-1">

                          <countdown v-if="quiz_countdown_time" :time="quiz_countdown_time" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }">
                            Time Remaining： {{ hours > 0 ? hours : '' }} {{hours > 0 ? 'hours ,' : ''}}  {{ minutes }} minutes, {{ seconds }} seconds.
                          </countdown>
                        </div>
                        <div class="d-flex flex-grow-1 justify-content-between">
                            <div>Attempt
                                <p>{{index+1}}/{{no_of_questions || 0 }}</p>
                            </div>

                        </div>
                        <div class="flex-grow-1 text-right">
                            <v-btn rounded @click="completeExam">
                                Submit
                            </v-btn>
                        </div>

                    </v-card-title>
                    <v-progress-linear
                            color="blue"
                            height="20"
                            :value="completed_percentage"
                            flat
                    >
                        <template v-slot:default="{  }">
                            <strong class="text-white">{{completed_percentage}}%</strong>
                        </template>
                    </v-progress-linear>
                    <v-row>
                        <v-col cols="4" v-if="quiz.show_questions">
                          <v-card>
                            <v-card-text>
                              <v-list-item v-for="(item, i) in questions" :key="i">
                                <v-list-item-content class="border mb-1 ml-2 px-3 "  @click="goToQuestion(item.question_no,item.is_attempted)"   :class="{'bg-attempt-question':item.is_attempted}">
                                  <v-list-item-title>
                                    <span class="font-weight-medium subtitle-1">{{item.question}}</span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card-text>

                          </v-card>

                        </v-col>
                        <v-col :cols="quiz.show_questions ? 8 : 12">

                            <v-card-text class="mt-5">
                                <v-skeleton-loader
                                        v-if="isBusy"
                                        type="table-heading, list-item-two-line, table-tfoot"
                                ></v-skeleton-loader>
                                <!--If One question per page-->
                                <div v-if="quiz.display_per_page == 1">
                                  <v-card outlined max-height="400" v-if="!isBusy && exam_question && !is_quiz_completed">
                                    <v-card-text>
                                        <div class="d-flex justify-content-center">
                                            <h4 v-text="exam_question.question"></h4>
                                        </div>
                                        <!--                                <div class="d-flex justify-content-center">-->
                                        <!--                                    <v-img-->
                                        <!--                                            lazy-src="https://picsum.photos/id/11/10/6"-->
                                        <!--                                            max-height="300"-->
                                        <!--                                            max-width="700"-->
                                        <!--                                            src="https://picsum.photos/id/11/500/300"-->
                                        <!--                                    ></v-img>-->
                                        <!--                                </div>-->
                                        <v-radio-group v-model="radios" class="mt-10" row>

                                            <v-row>
                                                <v-col cols="6" v-for="(option,option_index) in exam_question.options"
                                                       :key="option_index">
                                                    <v-radio @click="changeAnswer()" :value="option" class="px-4 py-3 rounded-pill"  active-class="active" :disabled="radio_button_disabled"
                                                             style="background-color: rgba(0, 0, 0, 0.05); ">
                                                        <template v-slot:label>
                                                          <div>
                                                                    <span class="text-uppercase">
                                                                          {{option_index | charIndex}} .
                                                                    </span>
                                                                {{option}}
                                                            </div>
                                                        </template>
                                                    </v-radio>
                                                </v-col>

                                            </v-row>


                                        </v-radio-group>


                                    </v-card-text>
                                    <v-card-text v-if="quiz.feedback_enable && submitted_answer && attempted_response">

                                      <div class="d-flex justify-content-center"
                                           v-if="checkCondition(submitted_answer,attempted_response.answer,'==')">
                                        <h4 class="text-success"> Congratulation your answer is correct</h4>
                                      </div>
                                        <div class="d-flex justify-content-center"
                                             v-if="checkCondition(submitted_answer,attempted_response.answer,'!=')">
                                            <h4 class="text-danger">Sorry Your Answer is Incorrect</h4> <br>
                                        </div>
                                        <div class="d-flex justify-content-center"
                                             v-if="checkCondition(submitted_answer,attempted_response.answer,'!=')">
                                          <h4 class="text-success"> Correct Answer is : {{submitted_answer}}</h4>
                                        </div>

                                    </v-card-text>
                                    <v-card-actions  >
                                      <v-spacer></v-spacer>
                                      <v-btn v-if="quiz.feedback_enable && submitted_answer" :disabled="buttonDisabled"  :loading="isBusy" class="text-white btn btn-primary"  depressed @click="nextQuestion()">
                                       Next question
                                      </v-btn>
                                      <v-btn v-if="!submitted_answer"  :disabled="buttonDisabled"  :loading="isBusy" class="text-white btn btn-primary"  depressed @click="submitAndNextQuestion()">
                                        Continue
                                      </v-btn>
                                    </v-card-actions>


                                </v-card>
                                </div>
                                <!--If more than One question per page-->
                                <div v-if="quiz.display_per_page > 1">
                                  <v-card class="mb-2" outlined min-height="500" v-show="!isBusy && limited_exam_question.length >0 && !is_quiz_completed" v-for="(limited_quiz,index) in limited_exam_question" :key="index">
                                    <v-card-text>
                                      <div class="d-flex justify-content-center">
                                        <h4 v-text="limited_quiz.question"></h4>
                                      </div>

                                      <v-radio-group v-model="multiple_radios[index]" class="mt-10" row>

                                        <v-row>
                                          <v-col cols="6" v-for="(item,item_index) in limited_quiz.options"
                                                 :key="item_index">
                                            <v-radio @click="changeMultipleAnswer(item,index,limited_quiz.question_no)" :value="item" class="px-4 py-3 rounded-pill"  active-class="active" :disabled="radio_button_disabled"
                                                     style="background-color: rgba(0, 0, 0, 0.05); ">
                                              <template v-slot:label>
                                                <div>
                                                  <span class="text-uppercase">
                                                    {{item_index | charIndex}} .
                                                  </span>
                                                  {{item}}
                                                </div>
                                              </template>
                                            </v-radio>
                                          </v-col>

                                        </v-row>


                                      </v-radio-group>
                                    </v-card-text>
                                  </v-card>
                                  <v-card class="mb-2" min-height="300" outlined v-show="!isBusy && group_submit_questions.length >0 && !is_quiz_completed"  v-for="(submitted_quiz,index) in group_submit_questions" :key="index">
                                   <v-card-text>
                                     <div class="d-flex justify-content-center">
                                       <h4 v-text="submitted_quiz.question"></h4>
                                     </div>
                                     <div class="d-flex justify-content-center"
                                          v-if=" submitted_quiz.correct_answer ==submitted_quiz.answer">
                                       <h4 class="text-success"> Congratulation your answer is correct</h4>
                                     </div>
                                     <div class="d-flex justify-content-center"
                                          v-if=" submitted_quiz.correct_answer != submitted_quiz.answer">
                                       <h4 class="text-danger">Sorry Your Answer is Incorrect</h4> <br>
                                     </div>
                                     <div class="d-flex justify-content-center"
                                          v-if="submitted_quiz.correct_answer != submitted_quiz.answer">

                                       <h4 class="text-success"> Correct Answer is : {{submitted_quiz.correct_answer}}</h4>
                                     </div>
                                   </v-card-text>

                                  </v-card>

                                  <v-card-actions  >
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="quiz.feedback_enable && group_submit_questions.length > 0 && limited_exam_question.length == 0" :disabled="buttonDisabled"  :loading="isBusy" class="text-white btn btn-primary"  depressed @click="nextGroupQuestion()">
                                      Next question
                                    </v-btn>
                                    <v-btn v-if="group_submit_questions.length == 0 && limited_exam_question.length > 0"  :disabled="buttonDisabled"  :loading="isBusy" class="text-white btn btn-primary"  depressed @click="submitGroupAnswer()">
                                      Continue
                                    </v-btn>
                                  </v-card-actions>
                                </div>
                                <v-card v-if="is_quiz_completed && show_answer">
                                  <v-card-text>
                                      <v-card

                                          class="mx-auto my-12"
                                          max-width="374"
                                      >
                                        <template slot="progress">
                                          <v-progress-linear
                                              color="deep-purple"
                                              height="10"
                                              indeterminate
                                          ></v-progress-linear>
                                        </template>
                                        <v-card-title>
                                          <v-row>
                                            <v-col md="6">
                                              Total marks : {{results.total_mark}}<br>
                                            </v-col>
                                            <v-col md="6">
                                              Yours marks : {{results.student_score}}
                                            </v-col>
                                          </v-row>


                                        </v-card-title>


                                        <v-card-text v-if="results">
                                          <div class="font-weight-bold text-success h5" v-if="results.result_status=='Passed'">
                                            Congratulations on passing your exams !!
                                          </div>
                                          <div class="font-weight-bold text-danger h5" v-if="results.result_status=='Failed'">
                                              Sorry you have failed.
                                          </div>


                                        </v-card-text>
                                      </v-card>
                                      <v-list-item two-line v-show="completed_question_answers.length > 0" v-for="(complete_quiz,index) in completed_question_answers" :key="index">
                                        <v-list-item-content class="border mb-1 ml-2 px-3 " :class="{ 'bg-success-answer': quiz.display_result && (complete_quiz.your_answer ==complete_quiz.correct_answer) }">
                                          <v-list-item-title>
                                            <span class="font-weight-bold h5">
                                              {{complete_quiz.question}}
                                            </span>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            <div class="text-secondary">
                                              <span class=" font-weight-medium subtitle-1">Your answer</span>  :<span class="text-danger font-weight-medium subtitle-1"> {{complete_quiz.your_answer}}</span>
                                            </div>
                                            <div class="text-secondary" v-if="quiz.display_result">
                                              <span class=" font-weight-medium subtitle-1"> Correct answer :</span> <span class="font-weight-medium text-success subtitle-1">  {{complete_quiz.correct_answer}}</span>
                                            </div>
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                  </v-card-text>
                                </v-card>
                                <v-overlay :value="isBusy">
                                    <v-progress-circular
                                            indeterminate
                                            size="64"
                                    ></v-progress-circular>
                                </v-overlay>
                            </v-card-text>

                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="sheet" persistent>
            <v-sheet
                    class="text-center"
                    height="200px"
            >
                <v-btn
                        class="mt-6"
                        text
                        color="red"
                        @click="sheet = !sheet"
                >
                    Close
                </v-btn>
                <div class="py-3">
                    Suspicious activity recorded !!
                    You cannot interact with other tabs.
                </div>
            </v-sheet>
        </v-bottom-sheet>

    </v-app>
</template>

<script>
    import {QuizMixin} from "@/mixins/QuizMixin";
    import QuizService from "@/core/services/quiz/QuizService";
    import * as moment from "moment";
    import "moment-duration-format";
    const quizService = new QuizService();


    export default {
        name: "quiz",
        mixins: [QuizMixin],
        data() {
            return {
                isBusy: false,
                dark: false,
                sheet: false,
                date: this.$moment(1),
                newDate: null,
                radios: null,
                multiple_radios: [],
                index: 0,
                suspicious_event_counter: 0,
                is_quiz_completed:false,
                show_answer:false,
                quiz_questions: [],
                attempted_response:null,
                exam_question:null,
                questions: [],
                submitted_answer:null,
                completed_question_answers:[],
                results:{},
                radio_button_disabled:false,
                buttonDisabled:true,
                limited_exam_question:[],
                group_question_answer:[],
                completed_percentage:0,
                group_submit_questions:[],
                quiz_countdown_time:null,
            }
        },
        watch: {
            index(newValue, oldValue) {
              this.fetchQuestion(this.exam_questions_codes[newValue]);
            }
        },
        methods: {
            suspicious(event) {
                this.suspicious_event_counter++;
               /* this.sheet = true;*/
                setTimeout(() => {
                    this.sheet = false;
                }, 5000)
            },
            completeExam(){
              let data = {'quiz_status' : 'complete'};
              this.completeExamRequest(data);
            },
            completeExamRequest(data){
            this.isBusy = true;
            quizService
                .completeExam(this.quiz_id,this.quiz_attempt.id,data)
                .then(response => {
                  this.$snotify.success("Exam Finished !");
                  this.quizAttemptSummary();
                })
                .catch(err => {
                  this.isBusy = false
                }).finally(()=>{
                  this.isBusy = false
                })
          },
            submitAndNextQuestion(){
                this.sendResponse();
                this.checkQuizRemainingQuestion();
                this.fetchAllQuestions();
            },

            submitAnswer(data){
              this.isBusy = true;
              quizService
                .submitAnswer(this.quiz_attempt.id,data)
                .then(response => {
                  this.submitted_answer = response.data.response;
                })
                .catch(err => {

                })
                .finally(()=>{
                  this.isBusy = false;
                })
          },
          //submitting single question answer

            sendResponse() {
              let response = {
                "question_no": this.exam_questions_codes[this.index],
                "answer": this.radios
              }
              this.attempted_response = {
                "question_no": this.exam_questions_codes[this.index],
                "answer": this.radios
              }
              this.submitAnswer(response);
            },
          //fetch single question
            fetchQuestion(question_code) {
              this.isBusy = true;
              quizService
                .fetchQuestion(this.quiz_id,question_code)
                .then(response => {
                    this.exam_question = response.data.question;
                }).catch(err => {
                  this.isBusy = false
                }).finally(()=>{
                  this.isBusy = false
                })
            },
          //Fetching All Question When Show question is enable
            fetchAllQuestions() {
              this.isBusy = true;
                quizService
                    .fetchAllQuestions(this.quiz_id)
                    .then(response => {
                        this.questions = response.data.questions;
                    })
                    .catch(err => {

                  })
                    .finally(()=>{
                      this.isBusy = false
                })
            },
            fetchTimeRemaining() {
               this.isBusy = true;
                quizService
                    .fetchTimeRemaining(this.quiz_id,this.quiz_attempt.id)
                    .then(response => {
                        this.time_remain = response.data;
                    })
                    .catch(err => {

                    })
                    .finally(()=>{
                      this.isBusy = false
                    })
          },
          //Checking whether quiz whole question is completed
            checkQuizRemainingQuestion(){
            quizService
                .checkRemainingQuestion(this.quiz_id,this.quiz_attempt.id)
                .then(response => {
                  let available_question_count=response.data.count_available_quiz_question;
                  this.completed_percentage=response.data.completed_percentage;
                  if(available_question_count > 0){
                    if(!this.quiz.feedback_enable){
                      this.nextQuestion();
                    }else{
                      this.radio_button_disabled=true;
                      this.quiz_countdown_time=null;
                    }
                  }else{
                    this.is_quiz_completed=true;
                    this.exam_question=null;
                    this.show_answer=true;
                    this.completeExam();
                  }
                  this.fetchAllQuestions();
                })
                .catch(err => {

                })
                .finally(()=>{
                  this.isBusy = false
                })
            },
            // Next Question
            nextQuestion(){
              this.index++;
              this.radios = null;
              this.submitted_answer = null;
              this.show_answer=false;
              this.is_quiz_completed=false;
              this.radio_button_disabled=false;
              this.attempted_response=null;
               this.countDownTime();
            },
            // Result Summary
            quizAttemptSummary(){
            quizService
                .showQuizAttemptSummary(this.quiz_id,this.quiz_attempt.id)
                .then(response => {
                  this.completed_question_answers=response.data.question_answers;
                  this.results=response.data.results;
                })
                .catch(err => {

                })
                .finally(()=>{

                })
          },
          changeAnswer(){
            this.buttonDisabled=false;
          },
          //When Whole time is finished ,to complete quiz
          completeRemainingQuiz(){
              quizService
                  .completeRemainingQuiz(this.quiz_id,this.quiz_attempt.id)
                  .then(response => {
                    this.is_quiz_completed=true;
                    this.exam_question=null;
                    this.show_answer=true;
                    this.completeExam();
                  })
                  .catch(err => {

                  })
                  .finally(()=>{

                  })

          },
          //for group question according to its limit
          getAllLimitedQuestion(){
              let data={
                'quiz_attempt_id':this.quiz_attempt.id
              };
              this.isBusy=true;
              this.limited_exam_question=[];
              quizService
                  .getLimitedQuestionByQuiz(this.quiz.id,data)
                  .then(response => {

                   this.multiple_radios=[];
                   if(response.data.count_available_quiz_question==0){
                     this.limited_exam_question=[];
                     this.is_quiz_completed=true;
                     this.exam_question=null;
                     this.show_answer=true;

                     this.completeExam();
                   }else{
                     this.limited_exam_question=response.data.limited_exam_question;
                   }
                  })
                  .catch(err => {

                  })
                  .finally(()=>{
                    this.isBusy=false;
                  })
            },
          //building array for multiple question to submt answer
          changeMultipleAnswer(item,index,question_no){
              let data={'question_no':question_no,'answer':item};
              var hasDuplicates =  this.group_question_answer.some(function(currentObject) {
                if(currentObject.question_no==question_no) {
                  return true;
                }else{
                  return false;
                }
              });
              this.group_question_answer.map(function (item1,index) {
                  if(item1.question_no==question_no){
                    delete item1.answer;
                    item1.answer=item;
                    return true;
                  }
             });
              if(!hasDuplicates){
                this.group_question_answer.push(data);
              }
              if(this.group_question_answer.length == this.limited_exam_question.length){
                this.buttonDisabled=false;
              }else{
                this.buttonDisabled=true;
              }
            },
          //submit group answer
          submitGroupAnswer(){
            if(this.group_question_answer.length > 0) {
                this.isBusy = true;
                quizService
                    .submitGroupAnswer(this.quiz_attempt.id,this.group_question_answer)
                    .then(response => {
                      if(this.quiz.feedback_enable){
                         this.group_submit_questions= response.data.group_submit_questions;
                         this.limited_exam_question=[];

                      }else{
                        this.group_question_answer=[];
                        this.getAllLimitedQuestion();
                        this.fetchAllQuestions();
                        this.buttonDisabled=true;
                      }
                    })
                    .catch(err => {

                    })
                    .finally(() => {

                      this.isBusy = false;
                    })
              }
          },
          goToQuestion(question_no,isAttempted){
            if(!isAttempted && this.quiz.display_per_page==1){
              this.fetchQuestion(question_no);
            }
          },
          //Once submitted group question calling api for next group question;
          nextGroupQuestion(){
            this.group_submit_questions=[];
            this.getAllLimitedQuestion();
            this.fetchAllQuestions();
            this.group_question_answer=[];
            this.buttonDisabled=true;
          },
          transformSlotProps(props) {
            const formattedProps = {};
            Object.entries(props).forEach(([key, value]) => {
              formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });
            return formattedProps;
          },
          //Running Countdown time
          countDownTime(){
            this.quiz_countdown_time=this.quiz.quiz_countdown_time;
            if(this.quiz){
              let duration_time=this.quiz.quiz_countdown_time;
              if(this.quiz.time_length && this.quiz.display_per_page==1){
                setInterval(function () {
                  this.radio_button_disabled=true;
                  this.buttonDisabled=false;
                },duration_time);
                const stopCountdown = setInterval(() => {
                  this.radio_button_disabled=true;
                  this.buttonDisabled=false;
                },duration_time)
              }else {

                const stopCountdown = setInterval(() => {
                    clearInterval(stopCountdown);
                  this.completeRemainingQuiz();
                },duration_time)
              }
            }
          },
          //Checking condition while feedbackenable
          checkCondition(submitted_answer,attempted_answer,condition){
              let a=null;
              let b=null;
              if(submitted_answer && attempted_answer){
                a=submitted_answer.trim();
                b=attempted_answer.trim();
                if(condition=='=='){
                  if(a==b){
                    return true;
                  }else{
                    return false
                  }
                }
                if(condition=='!='){
                  if(a!=b){
                    return true;
                  }else{
                    return false;
                  }
                }
              }
              }

        },
        filters: {
          charIndex: function (i) {
            return String.fromCharCode(97 + i);
          }
        },
        computed: {

        },
        mounted: function () {
            if(this.quiz.display_per_page > 1){
            this.getAllLimitedQuestion();
           }else{
             if (this.exam_questions_codes.length) {
               this.fetchQuestion(this.exam_questions_codes[this.index])

             }
           }
          this.fetchAllQuestions();
          this.fetchTimeRemaining();
          this.countDownTime();
            // this.date =  this.should_complete_time;
        },
    }
</script>

<style scoped>
.bg-attempt-question{
  background-color: #f5bbbb ;
}
.bg-success-answer{
  background-color: #7FFFD4 ;
}
.active  {
  border: 2px solid blue;
}
</style>